<template>
  <validation-observer ref="simpleRules">
    <div>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="card">
          <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
            <h3 class="font-weight-bolder">
              {{ $t('key-318') }}
            </h3>

            <div class="d-flex">
              <div class="income-order-search order-search w-100">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25">
                    <input
                      v-model="search_val"
                      type="text"
                      :placeholder="$t('search')"
                      class="anan-input__input"
                      @keyup.enter="getData()"
                    >
                    <div class="anan-input__suffix">
                      <button
                        type="button"
                        class="anan-button anan-button--normal anan-button--primary"
                        @click="getData"
                      >
                        <i class="fal fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <!-- <div class="row"> -->
            <div class="d-flex ">
              <div>
                <div for="dateStart">
                  {{ $t('key-230') }}
                </div>
                <date-picker
                  v-model="dateStart"
                  type="month"
                  format="เดือน MMM ปี YYYY"
                  :placeholder="$t('key-231')"
                  @change="getData"
                />
              </div>
              <button
                type="button"
                class="anan-button--xl-large anan-button anan-button--primary anan-button--normal mt-2 ml-50"
                @click="printcommission()"
              >
                <span>
                  <feather-icon
                    icon="PrinterIcon"
                    class="mr-25 cursor-pointer"
                  /> {{ $t('print') }}
                </span>
              </button>
            </div>
            <!-- <div class="col-md-3">
              <button
                type="button"
                class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
                @click="printcommission()"
              >
                <span>
                  <feather-icon
                    icon="PrinterIcon"
                    class="mr-25 cursor-pointer"
                  /> {{ $t('print') }}
                </span>
              </button>
            </div> -->
            <!-- </div> -->

          </div>
          <div class="pl-2 pr-2 pt-1">
            <b-table
              striped
              hover
              responsive
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(name_lot)="data">
                {{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </template>
              <template #cell(itemnumber_text)="data">
                <router-link :to="{name : 'shipment_payment_edit', params: { id: data.item.ref_id }}">
                  {{ data.item.itemnumber_text }}
                </router-link>
              </template>
              <template #cell(username)="data">
                <router-link :to="{name : 'admin-employee-edit', params: { id: data.item.uid }}">
                  {{ data.item.username }}
                </router-link>
              </template>
              <template #cell(charge_cus)="data">
                {{ Commas(data.item.charge_cus) }} ฿
              </template>
              <template #cell(charge_per_cue)="data">
                {{ CommasCue(data.item.charge_per_cue) }}
              </template>

              <template #cell(commission)="data">
                <!-- {{ Commas(data.item.amount) }} -->
                <span :style="getAmountStyle(data.item.status)">
                  {{ getAmountPrefix(data.item.status) }} {{ Commas(data.item.commission) }} ฿
                </span>
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </div>
      </b-overlay>
      <b-modal
        id="modal-add-new"
        :title="$t('addProductLot')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok.prevent="submitAddNew()"
      >

        <label for="Type">{{ $t('transportType') }} <span class="text-danger">*</span></label>
        <div class="demo-inline-spacing mt-n1">
          <b-form-radio
            v-model="transportType"
            name="some-radios"
            value="1"
          >
            {{ $t('truck') }}
          </b-form-radio>
          <b-form-radio
            v-model="transportType"
            name="some-radios"
            value="2"
          >
            {{ $t('ship') }}
          </b-form-radio>
        </div>
      </b-modal>

      <!-- <b-modal
        ref="modal-print"
        :title="$t('PrintProductlot')"
        :ok-title="$t('print')"
        :cancel-title="$t('cancel')"
        size="lg"
        @ok="submitPrint()"
      >
        <div class="d-flex align-items-center">
          <div class="w-45">
            <div class="card border">
              <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="checkAll"
                    value="true"
                    class="custom-control-primary"
                    :indeterminate="isIndeterminate"
                    @change="checkAllOptions"
                  />
                  <h6 class="mb-0">
                    <span v-if="roleDetail.length">{{ roleDetail.length }}/</span>{{ optionsLeft.length }} items
                  </h6>
                </div>

                <h6 class="mb-0">
                  {{ $t('listlot') }}
                </h6>
              </div>

              <div class="p-1">
                <div class="income-order-search order-search">
                  <div class="anan-input search-input">
                    <div class="anan-input__inner anan-input__inner--normal">
                      <input
                        v-model="searchRight"
                        type="text"
                        placeholder="Search here..."
                        class="anan-input__input"
                      >
                      <div class="anan-input__suffix">
                        <i class="anan-input__clear-btn anan-icon" />
                        <i class="anan-input__suffix-icon anan-icon">
                          <i class="fal fa-search" />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="role-items">
                  <b-form-group class="mt-1">
                    <b-form-checkbox
                      v-for="option in filteredOptions"
                      :key="option.name_lot"
                      v-model="roleDetail"
                      :value="option._id"
                      name="flavour-3a"
                    >
                      <div class="mb-1">
                        {{ option.transportType === 1 ? 'รถ':'เรือ' }}/{{ option.name_lot }}
                      </div>
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <div class="w-10 p-50">
            <div>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-icon-only w-100 mb-1"
                @click="moveToLeft"
              >
                <i class="fas fa-arrow-to-left" />
              </button>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-icon-only w-100"
                @click="moveToRight"
              >
                <i class="fas fa-arrow-from-left" />
              </button>
            </div>
          </div>

          <div class="w-45">
            <div class="card border">
              <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="checkAll2"
                    value="true"
                    class="custom-control-primary"
                    :indeterminate="isIndeterminate2"
                    @change="checkAllOptions"
                  />
                  <h6 class="mb-0">
                    <span v-if="roleDetail2.length">{{ roleDetail2.length }}/</span>{{ optionsRight.length }} items
                  </h6>
                </div>

                <h6 class="mb-0">
                  {{ $t('selectlot') }}
                </h6>
              </div>

              <div class="p-1">
                <div class="income-order-search order-search">
                  <div class="anan-input search-input">
                    <div class="anan-input__inner anan-input__inner--normal">
                      <input
                        v-model="searchLeft"
                        type="text"
                        placeholder="Search here..."
                        class="anan-input__input"
                      >
                      <div class="anan-input__suffix">
                        <i class="anan-input__clear-btn anan-icon" />
                        <i class="anan-input__suffix-icon anan-icon">
                          <i class="fal fa-search" />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="role-items">
                  <b-form-group class="mt-1">
                    <b-form-checkbox
                      v-for="option in filteredOptions2"
                      :key="option.name_lot"
                      v-model="roleDetail2"
                      :value="option._id"
                      name="flavour-3a"
                    >
                      <div class="mb-1">
                        {{ option.transportType === 1 ? 'รถ':'เรือ' }}/{{ option.name_lot }}
                      </div>
                    </b-form-checkbox>
                  </b-form-group>
                </div>

              </div>
            </div>
          </div>
        </div>
      </b-modal> -->
    </div>
  </validation-observer>
</template>

<script>
import {
  BTable, BLink,
  // BFormSelect,
  BModal, BPagination, BFormRadio, BFormGroup, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import moment from 'moment-timezone'

export default {
  components: {
    DatePicker,
    BTable,
    BLink,
    // BFormSelect,
    BModal,
    BPagination,
    BFormRadio,
    BFormGroup,
    BFormCheckbox,
    BOverlay,
  },
  data() {
    return {
      role: '',
      searchLeft: '',
      searchRight: '',
      roleDetail: [],
      roleDetail2: [],
      optionsRight: [],
      optionsLeft: [],
      checkAll: false,
      checkAll2: false,
      search: '',
      selected: null,
      transportType: null,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      number_lot: 0,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        title: '',
        content: '',
      },
      items: [
      ],
      showOver: false,
      search_val: '',
      dateStart: new Date(),
      dateEnd: new Date(),
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'name_lot', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'itemnumber_text', label: this.$t('key-319'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customer'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'charge_per_kilo', label: this.$t('key-320'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'charge_per_cue', label: this.$t('key-321'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'charge_cus', label: this.$t('key-322'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'commission', label: 'commission', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  async mounted() {
    this.getData()
    this.sharedMethod()
  },
  methods: {
    async getData() {
      try {
        const params = {
          perPage: this.perPage,
          page: this.currentPage,
          search_val: this.search_val,
          dateEnd: this.dateEnd,
          dateStart: this.dateStart,
        }
        // console.debug(params)
        const { data: res } = await this.$http.get('Commission/getCommission', { params })
        this.items = res.data
        this.totalRows = res.total
      } catch (err) {
        console.log(err)
      }
    },

    printcommission() {
      if (this.items.length > 0) {
        this.showOver = true
        // eslint-disable-next-line no-underscore-dangle
        const Printcommis = {
          search_val: this.search_val,
          dateEnd: this.dateEnd,
          dateStart: this.time(this.dateStart),
          id: this.UserData.userID,
          agent_id: this.UserData.agent_id,
        }
        this.$http.post('/print/Commission', Printcommis, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'commission'
            link.click()
            this.showOver = false
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
            this.showOver = false
          })
      }
    },
    time(data) {
      const formattedDate = moment(data).format('YYYY-MM-DD')
      const startOfFormattedDate = moment(formattedDate)
        .endOf('month')
        .format('YYYY-MM-DD')
      return startOfFormattedDate
    },
    getAmountStyle(item) {
      if (item) {
        return { color: 'green' }
      }
      return { color: 'red' }
    },
    getAmountPrefix(item) {
      if (item) {
        return '+'
      }
      return '-'
    },
    Document(item) {
      try {
        // eslint-disable-next-line no-underscore-dangle
        window.open(`https://api.anan-cargo.com/api/print/pickup/${item._id}`)
        // window.open(`http://127.0.0.1:4444/api/print/pickup/${this.ID}`)
      } catch (e) {
        console.log(e)
      }
    },
    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    getType(type) {
      if (type === 1) {
        return 'รถ'
      } return 'เรือ'
    },
  },
}
</script>
  <style lang="scss" scoped></style>
